import { Injectable } from '@angular/core';
import { SchedulingApiShiftModel } from '@plano/client/scheduling/shared/api/scheduling-api-shift-model.service';
import { SchedulingApiBooking, SchedulingApiBookingParticipant, SchedulingApiShiftModelCoursePaymentMethods, SchedulingApiShiftModelCourseTariff, SchedulingApiShiftModelCourseTariffFee, SchedulingApiShiftModelCourseTariffs } from '@plano/shared/api';
import { Id } from '@plano/shared/api/base/id/id';

@Injectable( { providedIn: 'root' } )
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PShiftmodelTariffService {
	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public setApplyAttributes(
		tariff : SchedulingApiShiftModelCourseTariff,
		booking : SchedulingApiBooking | null = null,
		participant : SchedulingApiBookingParticipant | null = null,
	) : void {
		const getId = (
			item : SchedulingApiBooking | SchedulingApiBookingParticipant | null,
			oldId : Id | null,
		) : Id | null => {
			const result : Id | null = item ? item.id : null;
			if (result && oldId && !result.equals(oldId)) {
				throw new Error('A single tariff cannot be applied to multiple bookings/participants.');
			}
			return result;
		};

		tariff.applyToBooking = getId(booking, tariff.applyToBooking);
		tariff.applyToParticipant = getId(participant, tariff.applyToParticipant);
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public tariffRadioIsDisabled(courseTariff : SchedulingApiShiftModelCourseTariff, selectedTariff : Id | null) : boolean {
		if (courseTariff.trashed && !courseTariff.id.equals(selectedTariff)) return true;

		return false;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public removeTariffFee(
		fee : SchedulingApiShiftModelCourseTariffFee,
		booking ?: SchedulingApiBooking | null,
		participant ?: SchedulingApiBookingParticipant | null,
	) : void {
		const tariff = fee.parent!.parent!;
		if (!fee.isNewItem()) { this.setApplyAttributes(tariff, booking, participant); }
		tariff.fees.removeItem(fee);
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public removeTariff(
		tariff : SchedulingApiShiftModelCourseTariff,
		shiftModel : SchedulingApiShiftModel,
	) : void {
		if (!tariff.isNewItem()) { this.setApplyAttributes(tariff); }
		if (tariff.isNewItem()) {
			shiftModel.courseTariffs.removeItem(tariff);
		} else {
			tariff.trashed = true;
		}
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public hasCourseDatesData(
		negateForCourseDatesInterval : SchedulingApiShiftModelCourseTariff['negateForCourseDatesInterval'] | undefined,
		forCourseDatesFrom : SchedulingApiShiftModelCourseTariff['forCourseDatesFrom'],
		forCourseDatesUntil : SchedulingApiShiftModelCourseTariff['forCourseDatesUntil'],
	) : boolean {
		if (negateForCourseDatesInterval === undefined) return false;

		if (negateForCourseDatesInterval === true) return true;
		if (forCourseDatesFrom) return true;
		if (forCourseDatesUntil) return true;
		return false;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public tariffIsAvailableAtDate(
		// TODO: Get rid of undefined here
		tariff : SchedulingApiShiftModelCourseTariff | null | undefined,
		relevantDate : number | null,
	) : boolean | undefined {

		// This code is more or less copy paste from:
		// https://drplano.atlassian.net/browse/PLANO-34855

		if (!tariff) return undefined;
		if (!relevantDate) return undefined;

		const from = tariff.forCourseDatesFrom;
		const until = tariff.forCourseDatesUntil;
		const negate = tariff.negateForCourseDatesInterval;

		const isInInterval = (!from || relevantDate >= from) && (!until || relevantDate < until);

		if (isInInterval === !negate) return true;

		return false;
	}

	/** @see PShiftmodelTariffService.isFreeCourse */
	public isFreeCourse(
		courseTariffs : SchedulingApiShiftModelCourseTariffs,
	) : boolean {
		return PShiftmodelTariffService.isFreeCourse(courseTariffs);
	}

	/**
	 * Under some circumstances this course gets visualized as 'for free' in the plugin.
	 * @param courseTariffs The tariffs of the item that should be checked
	 */
	public static isFreeCourse(
		courseTariffs : SchedulingApiShiftModelCourseTariffs,
	) : boolean {
		return !PShiftmodelTariffService.hasVisibleCourseTariffWithCosts(courseTariffs);
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public hasVisiblePaymentMethod(coursePaymentMethods : SchedulingApiShiftModelCoursePaymentMethods) : boolean {
		return PShiftmodelTariffService.hasVisiblePaymentMethod(coursePaymentMethods);
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public static hasVisiblePaymentMethod(
		coursePaymentMethods : SchedulingApiShiftModelCoursePaymentMethods,
	) : boolean {
		const ACTIVE_PAYMENT_METHOD = coursePaymentMethods.findBy(coursePaymentMethod => {
			if (coursePaymentMethod.trashed) return false;
			if (coursePaymentMethod.isInternal) return false;
			return true;
		});
		return !!ACTIVE_PAYMENT_METHOD;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public hasVisibleCourseTariffWithCosts(
		courseTariffs : SchedulingApiShiftModelCourseTariffs,
	) : boolean {
		return PShiftmodelTariffService.hasVisibleCourseTariffWithCosts(courseTariffs);
	}

	/**
	 * Checks if at least one course tariff is not trashed and has costs
	 */
	public static hasVisibleCourseTariffWithCosts(
		courseTariffs : SchedulingApiShiftModelCourseTariffs,
	) : boolean {
		const ACTIVE_TARIFF = courseTariffs.findBy(tariff => {
			if (tariff.trashed) return false;
			if (tariff.isInternal) return false;
			return !!tariff.fees.findBy(fee => fee.fee > 0);
		});
		return !!ACTIVE_TARIFF;
	}

	/* eslint-disable-next-line jsdoc/require-jsdoc */
	public hasInBookingPluginVisibleTariff(
		courseTariffs : SchedulingApiShiftModelCourseTariffs,
	) : boolean {
		const IN_BOOKING_PLUGIN_VISIBLE_TARIFF = courseTariffs.findBy(tariff => {
			if (tariff.trashed) return false;
			if (tariff.isInternal) return false;
			return true;
		});
		return !!IN_BOOKING_PLUGIN_VISIBLE_TARIFF;
	}
}
